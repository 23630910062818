import React from 'react'
import {Modal} from "react-bootstrap";
import LoadableButton from "./LoadableButton";

export default function TwoFAModal(props) {
    return (<div style={{
        width: '100%',
        height: '100%',
        display: "block",
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 99999,
        backgroundColor: 'rgba(0,0,0,40%)'
    }}>
        <div style={{
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '200px',
            backgroundColor: 'white',
            display: 'block'
        }}>
            <Modal.Header>
                <Modal.Title>Your account have to activate 2FA this is necessary by executive in RES Global
                    Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>What is Two-factor authentication (2FA)?</h4>
                <p>Two-factor authentication adds an additional layer of security to the authentication
                    process by making it harder for attackers to gain access to a person's devices or online
                    accounts because, even if the victim's password is hacked, a password alone is not
                    enough to pass the authentication check.</p>
                <p>2FA is implemented to better protect both a user's credentials and the resources the user can access.
                    Two-factor authentication provides a higher level of security than authentication methods that
                    depend on single-factor authentication (SFA), in which the user provides only one factor --
                    typically, a password or passcode. Two-factor authentication methods rely on a user providing a
                    password as the first factor and a second, different factor -- usually either a security token or a
                    biometric factor, such as a fingerprint or facial scan.
                </p>
                <p>
                    Two-factor authentication adds an additional layer of security to the authentication process by
                    making it harder for attackers to gain access to a person's devices or online accounts because, even
                    if the victim's password is hacked, a password alone is not enough to pass the authentication check.
                </p>
                <p>
                    Two-factor authentication has long been used to control access to sensitive systems and data. Online
                    service providers are increasingly using 2FA to protect their users' credentials from being used by
                    hackers who stole a password database or used phishing campaigns to obtain user passwords.</p>
                <p>To activate 2FA click below button</p>
                <LoadableButton
                    block
                    bsStyle="primary"
                    onClick={props.goTo2FAPage}
                >Go to 2FA options</LoadableButton>
            </Modal.Body>
        </div>
    </div>)
}