import React from "react";
import { Modal, FormControl, Button, FormGroup, ControlLabel } from "react-bootstrap";

export default class TwoFAConfirmCodeModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClosed}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you certain about disabling two-step authorization for this account?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.props.helpText}</p>
                    <FormGroup>
                        <ControlLabel>Confirm your decision by entering your 2FA code</ControlLabel>
                        <FormControl
                            maxLength={6}
                            type="text"
                            placeholder="6-number code"
                            value={this.props.confirmCode}
                            onChange={this.props.modalConfirmCodeUpdate}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClosed}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        onClick={this.props.onConfirm}
                        disabled={this.props.confirmCode}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
