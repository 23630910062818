import {
    STATE_NEW,
    STATE_CHANGED,
    ERROR_CHANGED,
    RESET_FOR_NEW_USER,
    DATA_LOADED,
    DISPLAY_NAME_CHANGED,
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    PASSWORD2_CHANGED,
    GROUP_CHANGED,
    BLOCKED_CHANGED,
    GROUP_DATA_CHANGED,
    GROUP_DATA_STATE_CHANGED,
    STATE_DEFAULT,
    OVERRIDE_CHANGED,
    OVERRIDES_CLEARED,
    CONNECTED_EMPLOYEE_CHANGED, TWO_FACTOR_AUTHORIZATION_CHANGED
} from "../actions/editUserActions";

export const initialState = {
    state: STATE_NEW,
    error: null,
    _id: null,
    displayName: "",
    email: "",
    password: "",
    password2: "",
    group: null,
    connectedEmployee: null,
    blocked: false,
    groupData: null,
    groupDataState: STATE_DEFAULT,
    permissionOverrides: []
};

export default function editUser(state = initialState, action) {
    switch (action.type) {
        case RESET_FOR_NEW_USER:
            return { ...initialState };
        case DATA_LOADED:
            return {
                ...initialState,
                ...action.payload
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case DISPLAY_NAME_CHANGED:
            return {
                ...state,
                displayName: action.payload
            };
        case EMAIL_CHANGED:
            return {
                ...state,
                email: action.payload
            };
        case PASSWORD_CHANGED:
            return {
                ...state,
                password: action.payload
            };
        case PASSWORD2_CHANGED:
            return {
                ...state,
                password2: action.payload
            };
        case TWO_FACTOR_AUTHORIZATION_CHANGED:
            return {
                ...state,
                enabled2FA: action.payload
            }
        case GROUP_CHANGED:
            return {
                ...state,
                group: action.payload
            };
        case BLOCKED_CHANGED:
            return {
                ...state,
                blocked: action.payload
            };
        case GROUP_DATA_CHANGED:
            return {
                ...state,
                groupData: action.payload
            };
        case GROUP_DATA_STATE_CHANGED:
            return {
                ...state,
                groupDataState: action.payload
            };
        case OVERRIDES_CLEARED:
            return {
                ...state,
                permissionOverrides: []
            };
        case OVERRIDE_CHANGED: {
            if (state.permissionOverrides.find(o => o.permission === action.permission)) {
                return {
                    ...state,
                    permissionOverrides: state.permissionOverrides.filter(o => o.permission !== action.permission)
                };
            } else {
                return {
                    ...state,
                    permissionOverrides: [
                        ...state.permissionOverrides,
                        {
                            permission: action.permission,
                            allow: action.payload
                        }
                    ]
                };
            }
        }
        case CONNECTED_EMPLOYEE_CHANGED:
            return {
                ...state,
                connectedEmployee: action.payload
            };
        default:
            return state;
    }
}
