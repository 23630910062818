import { apiFetch } from "../../actions/apiActions";
import { authCheckRequested } from "../../actions/authActions";
import {twoFAisActiveChanged} from "../../UsersPage/actions/editUserActions";
import {setNewPassword} from "./changePasswordActions";

export const URL_CHANGED = "TWO_FA.URL_CHANGED";
export const SECRET_CHANGED = "TWO_FA.SECRET_CHANGED";
export const ENABLED2FA_CHANGED = "TWO_FA.ENABLED2FA_CHANGED";
export const STATUS_CHANGED = "TWO_FA.STATUS_CHANGED";

export function urlChanged (url) {
    return {
        type: URL_CHANGED,
        url
    };
}
export function secretChanged (secret) {
    return {
        type: SECRET_CHANGED,
        secret
    };
}
export function enabled2FAChanged (enabled2FA) {
    return {
        type: ENABLED2FA_CHANGED,
        enabled2FA
    };
}
export function statusChanged (status) {
    return {
        type: STATUS_CHANGED,
        status
    };
}

export function getQRcode() {
    return async (dispatch) => {
        try {
            let getQRcode = await dispatch(
                apiFetch("/api/auth/generate_QR")
            );

            let qrCodeData = await getQRcode.json();
            dispatch(urlChanged(qrCodeData.url));
            dispatch(secretChanged(qrCodeData.secret));
            dispatch(enabled2FAChanged(qrCodeData.enabled2FA));

        } catch (e) {
            console.log('error')
            console.log(e)
            dispatch(statusChanged('Problem with server response. Please try again later.'))
        }
    };
}

export function set2FA(code) {
    return async (dispatch) => {
        try {
           let set2FA = await dispatch(apiFetch("/api/auth/set2FA", {method: "POST", body: JSON.stringify({code})}));
           let set2FAData = await set2FA.json();
           dispatch(statusChanged(set2FAData.status));
           if(set2FAData.code === true){
               dispatch(enabled2FAChanged(true));
               await dispatch(authCheckRequested())
           }
         }
         catch (e) {
            console.log('error');
            console.log(e);
         }
    }
}

export function confirm2FA(code) {
    return async (dispatch) => {
        try {
           let set2FA = await dispatch(apiFetch("/api/auth/set2FA", {method: "POST", body: JSON.stringify({code})}));
           let set2FAData = await set2FA.json();
           dispatch(statusChanged(set2FAData.status));
           if(set2FAData.code === true){
               dispatch(twoFAisActiveChanged(false))
           }
         }
         catch (e) {
            console.log('error');
            console.log(e);
         }
    }
}

export function confirm2FADuringPasswordChanging(data) {
    return async (dispatch) => {
        try {
           let set2FA = await dispatch(apiFetch("/api/auth/set2FA", {method: "POST", body: JSON.stringify({code: data.code})}));
           let set2FAData = await set2FA.json();
           if(set2FAData.code === true){
               dispatch(setNewPassword(data));
           } else {
               //should add error message during after send bad 2fa token
           }
         }
         catch (e) {
            console.log('error');
            console.log(e);
         }
    }
}

